import React from "react"
import FooterComponent from "@heyhabito/footer"
import { useTrustpilot } from "../Trustpilot/hooks"

const Footer: React.FunctionComponent = () => {
  const trustpilot = useTrustpilot()
  return <FooterComponent trustpilot={trustpilot} />
}

export default Footer
