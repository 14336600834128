import { FC } from "react"
import styled from "@emotion/styled"
import { breakpoints } from "design-kit"

import {
  ArrowCardContainer,
  ArrowCardData,
} from "../../../shared-components/ArrowCardContainer/ArrowCardContainer"

const arrowCardsItems: ArrowCardData[] = [
  {
    articleTitle: "Buy-to-let mortgages: an introduction",
    description:
      "Not sure where to start with buy-to-let? Read our plain English guide.",
    buttonHref: "https://www.habito.com/home/buy-to-let-mortgages",
  },
  {
    articleTitle: "Compare live mortgage rates",
    description:
      "Use our mortgage comparison table to get a feel for what's out there.",
    buttonHref:
      "/mortgage-comparison?buyerType=NewMortgage&initialProduct=TwoYearFixed&mortgageAmountV2=16000000&mortgageTerm=25&mortgageType=BuyToLet&ordering=OverallCostAsc&propertyValueV2=20000000&repaymentMethod=InterestOnly",
  },
  {
    articleTitle: "How to remortgage your buy-to-let loan",
    description:
      "Save money, or borrow more. It's usually pretty straightforward to remortgage your buy-to-let loan.",
    buttonHref:
      "https://www.habito.com/home/buy-to-let-mortgages#remortgaging-your-buy-to-let-loan",
  },
]

const Inner = styled.div`
  ${breakpoints.tablet`
    display: flex;
    justify-content: space-between;
  `}
`

const ExplainerCards: FC = () => (
  <Inner>
    <ArrowCardContainer cards={arrowCardsItems} />
  </Inner>
)

export default ExplainerCards
