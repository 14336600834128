import { useStaticQuery, graphql } from "gatsby"

export interface TrustpilotHook {
  stars: 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5
  description: string
  trustScore: number
  numberOfReviews: number
  numberOfReviewsFormatted: string
}

export const useTrustpilot = (): TrustpilotHook => {
  const data = useStaticQuery(graphql`
    {
      trustpilot {
        score {
          stars
          trustScore
          description
        }
        numberOfReviews {
          total
        }
      }
    }
  `)
  const habito = data.trustpilot
  const numberOfReviews = habito.numberOfReviews.total

  const roundDownToNearestHundred: (x: number) => number = (x: number) => {
    return Math.floor(x / 100) * 100
  }

  // Note: We can't use maximumSignificantDigits to do the rounding, as it uses
  // the bankers rounding method, which might round up.
  const numberOfReviewsFormatted = new Intl.NumberFormat("en-GB").format(
    roundDownToNearestHundred(numberOfReviews)
  )

  return {
    stars: habito.score.stars,
    trustScore: habito.score.trustScore,
    description: habito.score.description,
    numberOfReviews,
    numberOfReviewsFormatted,
  }
}
