import React, { FC } from "react"
import styled from "@emotion/styled"
import {
  Body,
  TextLink,
  horizontal,
  typographyStyles,
  vertical,
} from "design-kit"

const Copy = styled(Body)`
  margin: 0 0 ${vertical.xs};
`

const Subheading = styled.h3`
  ${typographyStyles.headlineBold};
  margin: 0 0 ${vertical.xs};
`

const Ul = styled.ul`
  ${typographyStyles.body};
  margin: 0 ${horizontal.l} ${vertical.s};
  list-style-type: disc;

  li {
    margin: 0 0 ${vertical.xxs};
  }
`

const IndividualVsCompany: FC = () => (
  <React.Fragment>
    <Subheading>How you’ll be taxed</Subheading>
    <Copy>
      If you buy your property as an individual, then your rental income will
      get taxed as personal income. You <em>can</em> claim some tax relief – but
      the amount you can claim is changing.
    </Copy>

    <Copy>
      Before 2017, landlords paid less tax on their rental income, because they
      could subtract certain costs from that income first – like the cost of
      their mortgage interest.
    </Copy>

    <Copy>
      But now, the government is phasing in some new rules. From April 2020, you
      have to pay tax on your rental income straight away, without deducting any
      costs. After that, you can only claim back the equivalent of 20% of your
      mortgage interest cost. Basically: you pay more tax.
    </Copy>

    <Copy>
      Now here’s the key point when it comes to company buy-to-let: these new
      rules <strong>don’t affect how companies are taxed</strong>. If you’re
      buying a property as a company, your company pays 19% corporation tax on
      the rental income. Plus, companies can also claim mortgage interest as a
      business expense, which reduces the amount that’s taxed.
    </Copy>

    <Copy>
      For some landlords, on the surface that might look like a much better
      deal. But – like with all things tax – it’s not that simple. That rental
      income your company collects <strong>belongs to your company</strong>. So
      if you want to withdraw that money (either in one go or on a regular
      basis) you’ll have to pay more tax to do it.
    </Copy>

    <Copy>There are two main ways you can pay yourself from your company:</Copy>

    <Ul>
      <li>
        <strong>Pay yourself in dividends.</strong> You get £2,000 worth of
        dividends tax free each year. After that, you’ll pay tax on those
        dividends (at 7.8%, 32.5%, or 38.1%, depending on your income tax rate).
      </li>

      <li>
        <strong>Pay yourself a salary, as an employee of the company.</strong>{" "}
        You’ll need to register your company for PAYE (pay as you earn), and pay
        national insurance and income tax on your salary to HMRC every month
        (even if you don’t pay yourself monthly). Your company will need to pay
        employer's national insurance on top of that – which is 13.8% of your
        salary each month. Because you’re now paying your regular tax and NI on
        top of corporation tax, this typically shakes out to be the costlier
        option of the two.
      </li>
    </Ul>

    <Subheading>Selling your property</Subheading>
    <Copy>
      OK, so now you want to sell your property. How does owning it as a company
      affect how you’re taxed on the profit?
    </Copy>

    <Copy>
      If you’ve bought as an individual, you’ll pay what’s called “capital gains
      tax” on any profit you earn from selling your property. Capital gains tax
      is either 18% or 28%, depending on your income and some other things (like
      how much you've been taxed before). If, like most people, you get an
      annual tax-free allowance – which is £12,500 from April 2019–2020 – you’ll
      only be taxed on profit over that amount.
    </Copy>

    <Copy>
      Companies, on the other hand, don’t pay capital gains tax – they’re
      charged the regular corporation tax rate on any profit earned from selling
      a property. The corporation tax rate is 19% (and 17% from April 2020). If
      you’re a higher rate tax payer, it might look like you pay less tax than
      individual landlords, but – like you might have guessed by now – it’s not
      that simple.
    </Copy>

    <Copy>
      As a company, you don’t get a tax-free allowance for corporation tax
      (though you do get something called{" "}
      <TextLink
        href="https://www.gov.uk/government/collections/corporation-tax-on-chargeable-gains-indexation-allowance-rates"
        text="indexation allowance"
      />
      ). And just like with rental income, you’ll have to pay tax to withdraw
      the funds from your company.
    </Copy>

    <Copy>
      That said, if you sell your property at a loss – then that’s technically
      your company’s loss, rather than yours personally. So you might be able to
      offset it against future earnings of your company (as long as you keep
      your company running).
    </Copy>

    <Subheading>What about the admin?</Subheading>
    <Copy>
      Setting up a buy-to-let mortgage inevitably means some paperwork, no
      matter how you choose to buy your property. But if you’re considering
      going down the company route, you’ll have a bit more on your plate.
    </Copy>

    <Copy>
      To set up a company, you’ll have to register with Companies House and
      submit some paperwork (like{" "}
      <TextLink
        href="https://www.gov.uk/limited-company-formation/memorandum-and-articles-of-association"
        text="articles of association"
      />
      ). You’ll have to make sure you’re keeping the right accounting records –
      in some cases, that means paying an accountant. And you’ll have to
      register for corporation tax, and submit a corporation tax return every
      year. (Of course, if you already have a company set up, you’re ready to
      go!)
    </Copy>

    <Copy>
      Then if you want to pay yourself a salary from your company, you’ll have
      to register for PAYE, then deduct tax and national insurance and pay that
      to HMRC monthly.
    </Copy>

    <Subheading>So in a nutshell: it depends</Subheading>
    <Copy>
      Like most things in life, whether you buy as an individual landlord or a
      company really depends – both on your circumstances, and on what you’re
      looking to get out of a buy-to-let.
    </Copy>

    <Copy>
      Always talk to your accountant, financial adviser or solicitor to help you
      make the best choice for you. Articles like these can never give you the
      whole story – so don’t rely on them for advice! Taxes are a total maze and
      there’s a lot more to them than we’ve described here – and ultimately, it
      will always depend on your unique situation.
    </Copy>

    <Copy>
      Once you’ve got professional advice and know what you’re after, we’ll help
      you get the right mortgage.{" "}
      <TextLink
        href="/my-properties"
        text="Chat to one of our expert brokers"
      />{" "}
      and we’ll sort it.
    </Copy>
  </React.Fragment>
)

export default IndividualVsCompany
