export interface Link {
  text: string
  url: string
}

export const aboutHabitoLinks: Link[] = [
  { text: "About us", url: "/about-us" },
  { text: "FAQs", url: "https://help.habito.com" },
  { text: "Lenders", url: "/lenders" },
  { text: "Terms of use", url: "/terms" },
  { text: "Privacy policy", url: "/privacy" },
  { text: "Cookie policy", url: "/cookies" },
]

export function mkAboutHabitoForIntermediariesLinks(
  intermediariesPrivacyPolicyURL: string,
  termsOfBusinessURL: string
): Link[] {
  return [
    { text: "About us", url: "/about-us" },
    { text: "FAQs", url: "https://help.habito.com" },
    { text: "Lenders", url: "/lenders" },
    { text: "Terms of use", url: "/terms" },
    { text: "Terms of business", url: termsOfBusinessURL },
    { text: "General Privacy policy", url: "/privacy" },
    {
      text: "Intermediaries Privacy policy",
      url: intermediariesPrivacyPolicyURL,
    },
    { text: "Cookie policy", url: "/cookies" },
  ]
}

export const resourcesLinks: Link[] = [
  { text: "Mortgage calculator", url: "/mortgage-calculator" },
  { text: "Mortgage comparison", url: "/mortgage-comparison" },
  { text: "Mortgage in principle", url: "/mortgage-in-principle" },
  { text: "Habito Hub ", url: "https://www.habito.com/hub" },
]

export const forCustomersLinks: Link[] = [
  {
    text: "Accessibility",
    url: "https://help.habito.com/en/articles/3224076-about-accessibility",
  },
  { text: "Log in", url: "/log-in" },
  { text: "Habito Plus", url: "/home-buying" },
]

export const contactLinks: Link[] = [
  { text: "Press", url: "/press" },
  { text: "Careers", url: "/careers" },
  { text: "Help centre", url: "https://help.habito.com" },
]

export const socialLinks: Link[] = [
  { text: "Twitter", url: "https://twitter.com/habito" },
  { text: "Facebook", url: "https://www.facebook.com/heyhabito" },
  { text: "Instagram", url: "https://www.instagram.com/habitomortgages" },
  { text: "LinkedIn", url: "https://www.linkedin.com/company/habito-" },
]
